import logo from "/assets/images/shopdeploy-logo.png";
import { Image } from "~/components/image";

export const support = [
  {
    name: "Help Docs",
    href: "https://www.shopdeploy.com/docs",
    description: "How to use Shopdeploy",
    props: { reloadDocument: true },
    icon: "",
  },
  {
    name: "Contact Us",
    href: "/contact",
    description: "We're happy to help",
    icon: "",
  },
  {
    name: "Takealot Seller Support",
    href: "/blog/takealot-seller-portal-contact",
    description: "Details for contacting Takealot",
    icon: "",
  },
  {
    name: "Seller Facebook Group",
    href: "https://www.facebook.com/groups/takealot",
    description: "Get help from other sellers",
  },
];

export const learn = [
  {
    name: "Shopdeploy blog",
    href: "/blog",
    description: "Ecommerce marketplace strategy tips",
    icon: "",
  },
  {
    name: "How to win the Buy Box",
    href: "/blog/how-to-win-the-takealot-buy-box",
    description: "Takealot Buy Box explainer",
    icon: "",
  },
  {
    name: "How to Sell on Takealot",
    href: "/how-to-sell-on-takealot",
    description: "Complete beginners guide to selling",
    icon: "",
  },
  {
    name: "Start an Ecommerce Business",
    href: "/start-ecommerce-business",
    description: "Blueprint to get you started",
    icon: "",
  },
];

export const ShopdeployLogo = () => {
  return (
    <div className="-m-1.5 flex-none p-1.5">
      <span className="sr-only">Shopdeploy</span>
      <Image
        responsive={[
          {
            size: {
              width: 215,
              height: 54,
            },
          },
          {
            size: {
              width: 143,
              height: 36,
            },
          },
        ]}
        options={{ quality: 100 }}
        src={logo}
        height={36}
        width={143}
        className="h-9 w-auto"
      />
    </div>
  );
};
export const NavHeaderContainer = ({ children }) => {
  return (
    <div className="border-b border-black/20 p-4">
      <div className="mx-auto flex max-w-7xl items-center justify-between lg:px-8">
        {children}
      </div>
    </div>
  );
};
