import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { Info, ArrowRight, BookOpen } from "react-feather";
import React, { Fragment, useState } from "react";

import { MobileMenu } from "~/components/nav/mobile-menu";
import {
  learn,
  support,
  NavHeaderContainer,
  ShopdeployLogo,
} from "~/components/nav/shared";

import { Link } from "@remix-run/react";

const Item = ({ name, href, description, props, ...rest }) => {
  return (
    <li>
      <Popover.Button
        as={Link}
        to={href}
        {...props}
        className={"group inline-block w-full text-black "}
      >
        <div className="group: flex items-center text-base font-medium">
          <span className="inline-block group-hover:text-slate-800">
            {name}
          </span>
          <ArrowRight
            className="group-hover:duration-250 group-hover:delay-50 -ml-1 origin-center text-white group-hover:translate-x-2.5 group-hover:text-slate-700 group-hover:transition-transform group-hover:ease-in"
            size={18}
          />
        </div>
        <div className="text-sm text-slate-800 group-hover:text-slate-700">
          {description}
        </div>
      </Popover.Button>
    </li>
  );
};

const ResourcePopoverContent = () => {
  return (
    <div className="px-4 text-slate-900">
      <div className="mx-auto grid max-w-7xl grid-cols-3 gap-x-6 py-10 lg:px-8 xl:gap-x-8">
        <div className="col-span-1">
          <div className="mb-5 flex items-center gap-x-2 border-b border-slate-700/20 pb-4">
            <Info size={32} />
            <h3 className="text-lg font-semibold ">Support</h3>
          </div>

          <ul className="mt-6 space-y-4">
            {support.map((item) => (
              <Item {...item} key={item.name} />
            ))}
          </ul>
        </div>
        <div className="col-span-2">
          <div className="mb-5 flex items-center gap-x-2 border-b border-slate-700/20 pb-4">
            <BookOpen size={32} />
            <h3 className="text-lg font-semibold ">Learn</h3>
          </div>
          <ul className="mt-6 space-y-4">
            {learn.map((item) => (
              <Item {...item} key={item.name} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

function FullWidthPopover({ title, children }) {
  return (
    <Popover>
      <Popover.Button className="text-md group -my-0.5 flex items-center gap-x-1 rounded-lg px-2 py-0.5 font-medium text-gray-700 outline-none focus:bg-slate-100">
        <span className="mr-1.5 hover:underline">{title}</span>
        <div
          aria-hidden="true"
          className="relative inline-block h-7 w-3 cursor-pointer "
        >
          <div className="ease-[bezier(0.66, 0.66, 0.34, 1.00)] inline-block h-2 w-[2px] origin-center -translate-x-[3px] rotate-[135deg] rounded border-b-8 border-slate-700 bg-slate-700 transition-all duration-[350ms] group-data-[headlessui-state=open]:rotate-45"></div>
          <div className="ease-[bezier(0.66, 0.66, 0.34, 1.00)] inline-block h-2 w-[2px] origin-center -rotate-[135deg] rounded border-b-8 border-slate-700 bg-slate-700 transition-all duration-[350ms] group-data-[headlessui-state=open]:-rotate-45"></div>
        </div>{" "}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default function NavMain() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: "Pricing", href: "/pricing" },
    {
      name: "Resources",
      href: "/blog",
      hasPopover: true,
      popoverContent: ResourcePopoverContent,
    },
    {
      name: "Help",
      href: "https://www.shopdeploy.com/docs",
      props: { reloadDocument: true },
    },
    { name: "Contact", href: "/contact" },
  ];

  return (
    <header className="relative isolate z-10 bg-white">
      <NavHeaderContainer>
        <Link to="/">
          <ShopdeployLogo />
        </Link>

        <div className="flex gap-x-5 lg:hidden">
          <Link to="https://app.shopdeploy.com/signup" className="underline">
            Start free trial
          </Link>
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
            aria-label="Mobile Menu"
            id="mobile-menu-open-button"
            name="Mobile Menu Open Button"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              className="h-6 w-6"
              title="Mobile Menu Open Button"
              aria-hidden="true"
              name="Mobile Menu Open Button"
              id="mobile-menu-open-button"
            />
          </button>
        </div>

        {/* Desktop Menu Items   */}
        <Popover.Group className="ms-8 hidden lg:flex lg:gap-x-8">
          {navigation.map((item) =>
            item.hasPopover ? (
              <FullWidthPopover key={item.name} title={item.name}>
                <item.popoverContent />
              </FullWidthPopover>
            ) : (
              <Link
                key={item.name}
                to={item.href}
                {...item.props}
                className="text-md group mr-1.5 rounded-lg px-2 py-0.5 font-medium text-gray-800 hover:underline focus:bg-slate-100 focus:ring-0"
                // text-gray-800 focus:outline-none focus:none focus:ring-blue-300 font-medium rounded-full text-md text-center px-5 py-2.5 -my-2.5 hover:underline
              >
                {item.name}
              </Link>
            ),
          )}
        </Popover.Group>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to="https://app.shopdeploy.com"
            className="focus:none text-md -my-2.5 rounded-full px-5 py-2.5 text-center font-medium text-gray-800 hover:underline focus:outline-none focus:ring-blue-300"
          >
            Log in
          </Link>
          <Link
            to="https://app.shopdeploy.com/signup"
            className="text-md -my-2.5 block rounded-full bg-orange-600 px-5 py-2.5 text-center font-medium text-white hover:bg-orange-500 focus:outline-none"
          >
            Start free trial
          </Link>
        </div>
      </NavHeaderContainer>

      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        navigation={navigation}
      />
    </header>
  );
}
