import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { Partytown } from "@builder.io/partytown/react";

import posthog from "posthog-js";
// import { analytics } from "./utils/segment";
import React, { useEffect } from "react";

import "./tailwind.css";

import Footer from "~/components/footer";
import Nav from "~/components/nav/nav";
// import { useSegment } from "~/hook/useSegment";

export function links() {
  return [
    {
      rel: "shortcut icon",
      href: "/favicon.ico",
      sizes: "16x16 24x24 32x32 48x48 64x64",
    },
    { rel: "icon", href: "/icons/icon-72x72.png", sizes: "72x72" },
    { rel: "apple-touch-icon", href: "/icons/icon-72x72.png", sizes: "72x72" },
    {
      rel: "apple-touch-icon-precomposed",
      href: "/icons/icon-72x72.png",
      sizes: "72x72",
    },
    {
      rel: "apple-touch-icon",
      href: "/icons/icon-144x144.png",
      sizes: "144x144",
    },
    {
      rel: "apple-touch-icon",
      href: "/icons/icon-256x256.png",
      sizes: "256x256",
    },
    {
      rel: "apple-touch-icon-precomposed",
      href: "/icons/icon-256x256.png",
      sizes: "256x256",
    },
  ];
}

const proxyScript = (url, location, type) => {
  if (
    [
      "googletagmanager.com",
      "googleads.g.doubleclick.net",
      "connect.facebook.net",
    ].includes(url.hostname)
  ) {
    var proxyUrl = new URL("https://walnut.shopdeploy.com/");
    proxyUrl.searchParams.append("url", url.href);
    // console.log(proxyUrl.href);
    return proxyUrl;
  }

  return url;
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://rsms.me/" />
        <Partytown
          // debug={true}
          forward={["dataLayer.push", "gtag", "fbq"]}
          resolveUrl={proxyScript}
          // logCalls={true}
        />
        <script
          type="text/partytown"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-HQ7RJG14P4"
        ></script>
        <script
          type="text/partytown"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
              window.gtag = function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
          
              gtag('config', 'G-HQ7RJG14P4', {
                page_path: window.location.pathname,
              });`,
          }}
        />

        <script
          type="text/partytown"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
             {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
             n.callMethod.apply(n,arguments):n.queue.push(arguments)};
             if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
             n.queue=[];t=b.createElement(e);t.async=!0;
             t.src=v;s=b.getElementsByTagName(e)[0];
             s.parentNode.insertBefore(t,s)}(window, document,'script',
             'https://connect.facebook.net/en_US/fbevents.js');
             fbq('init', '2741524815877483');
             fbq('track', 'PageView');`,
          }}
        />

        <Meta />
        <Links />
      </head>
      <body>
        <div>
          <Nav />
          {children}
          <Footer />
          <ScrollRestoration />
          <Scripts />
        </div>
        {/* <SpeedInsights /> */}
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=2741524815877483&ev=PageView&noscript=1"
          />
        </noscript>
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

function App() {
  const location = useLocation();
  // useSegment(import.meta.env.VITE_SEGMENT_WRITE_KEY);

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-HQ7RJG14P4", {
        page_path: location.pathname,
      });
    }
  }, []);

  return <Outlet />;
}

export default withSentry(App);
