import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { Info, BookOpen } from "react-feather";
import React, { Fragment } from "react";

import {
  learn,
  support,
  NavHeaderContainer,
  ShopdeployLogo,
} from "~/components/nav/shared";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";

const CssArrow = () => {
  return (
    <div
      aria-hidden="true"
      className="relative inline-block h-7 w-3 cursor-pointer "
    >
      <div className="ease-[bezier(0.66, 0.66, 0.34, 1.00)] inline-block h-2 w-[2px] origin-center -translate-x-[3px] rotate-[135deg] rounded border-b-8 border-slate-700 bg-slate-700 transition-all duration-[350ms] group-data-[headlessui-state=open]:rotate-45"></div>
      <div className="ease-[bezier(0.66, 0.66, 0.34, 1.00)] inline-block h-2 w-[2px] origin-center -rotate-[135deg] rounded border-b-8 border-slate-700 bg-slate-700 transition-all duration-[350ms] group-data-[headlessui-state=open]:-rotate-45"></div>
    </div>
  );
};

const MobileLink = ({ name, description, href, closeMenu }) => {
  return (
    <li>
      <Link to={href} onClick={closeMenu}>
        <div className="group: flex items-center text-base font-medium">
          <span className="inline-block group-hover:text-slate-800">
            {name}
          </span>
        </div>
        <div className="text-sm text-slate-800 group-hover:text-slate-700">
          {description}
        </div>
      </Link>
    </li>
  );
};

function MobileDisclosure({ closeMenu }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="group inline-flex text-xl font-bold sm:text-2xl lg:font-normal">
            <span className=" mr-3">Resources</span>

            <CssArrow />
          </Disclosure.Button>
          <Disclosure.Panel className="text-slate-700-500 px-2 pb-2 pt-4 text-sm">
            <div className="grid gap-y-8">
              <div className="col-span-1 border-l border-slate-700/20 pl-2">
                <div className="mb-2 flex items-center gap-x-2 ">
                  <Info size={32} />
                  <h3 className="text-lg font-semibold ">Support</h3>
                </div>

                <ul className="mt-6 space-y-3 sm:columns-2">
                  {support.map((item) => (
                    <MobileLink
                      {...item}
                      key={item.name}
                      closeMenu={closeMenu}
                    />
                  ))}
                </ul>
              </div>

              <div className="col-span-1 border-l border-slate-700/20 pl-2">
                <div className="mb-2 flex items-center gap-x-2 ">
                  <BookOpen size={32} />
                  <h3 className="text-lg font-semibold ">Learn</h3>
                </div>

                <ul className="mt-6 space-y-3 sm:columns-2">
                  {learn.map((item) => (
                    <MobileLink
                      {...item}
                      key={item.name}
                      closeMenu={closeMenu}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export const MobileMenu = ({
  mobileMenuOpen,
  setMobileMenuOpen,
  navigation,
}) => {
  return (
    <Transition show={mobileMenuOpen} as={Fragment}>
      <Dialog
        as="div"
        className="lg:hidden"
        onClose={() => setMobileMenuOpen(false)}
        // open={mobileMenuOpen}
      >
        <Transition.Child
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Panel className="nav:hidden fixed bottom-0 top-0 z-10 flex w-full flex-col  bg-white">
            <NavHeaderContainer>
              <Link to="/" onClick={() => setMobileMenuOpen(false)}>
                <ShopdeployLogo />
              </Link>
              <div className="flex gap-5">
                <Link
                  to="https://app.shopdeploy.com/signup"
                  className="underline"
                >
                  Start free trial
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                    title="Mobile Menu Close Button"
                  />
                </button>
              </div>
            </NavHeaderContainer>

            <nav className="no-scrollbar mt-8 overflow-y-auto px-3 sm:mt-10">
              <ul>
                {navigation.map((item) =>
                  item.hasPopover ? (
                    <li className="mb-6 text-base sm:mb-8" key={item.name}>
                      <MobileDisclosure
                        closeMenu={() => setMobileMenuOpen(false)}
                      />
                    </li>
                  ) : (
                    <li className="mb-6 text-base sm:mb-8" key={item.name}>
                      <Link
                        to={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="mr-3 text-xl font-bold sm:text-2xl lg:font-normal"
                        {...item.props}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </nav>
            <div className="relative mt-auto flex flex-col border-t text-center">
              <Link
                to="https://app.shopdeploy.com/"
                className="m-3 mt-5 rounded-lg border-2 border-slate-500 p-3 text-base font-semibold leading-7 text-slate-900 hover:bg-gray-100"
              >
                Log in
              </Link>
              <Link
                to="https://app.shopdeploy.com/signup"
                className="mx-3 mb-5 rounded-lg border-2 border-orange-600 bg-orange-600 p-3 text-base font-semibold leading-7 text-white hover:bg-orange-500"
              >
                Start Free Trial
              </Link>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
